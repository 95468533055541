import { useState, useEffect } from "react";
import logo from "./logo.svg";
import styles from "./App.module.css";
import { db } from "./firebase/firebase";
import { doc, getDoc } from "firebase/firestore";
import { HiOutlineAtSymbol } from "react-icons/hi";
import { FaPhoneAlt } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { IoLink } from "react-icons/io5";
import { TfiWorld } from "react-icons/tfi";
import CardInfoRow from "./components/CardInfoRow";
import {
  isRouteErrorResponse,
  useParams,
  useLoaderData,
} from "react-router-dom";
import { MdOutlineSaveAlt } from "react-icons/md";

import PropagateLoader from "react-spinners/PropagateLoader";
import { Drawer } from "vaul";
import { MyDrawer } from "./components/MyDrawer";

// TODO: move to another folder with all the loaders
export async function loader({ params }) {
  const docRef = doc(db, "users", params.username);
  const userSnap = await getDoc(docRef);
  let data;

  if (userSnap.data()) {
    data = userSnap.data();
    return { data };
  }

  throw new Response("Not Found", { status: 404 });
}

function App() {
  const [isLoading, setIsLoading] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const { data } = useLoaderData();
  console.log(data);

  console.log(isOpen);

  function bottomSheetHandler() {
    setIsOpen((state) => !state);
  }

  function generateVCard() {
    setIsLoading(true);
    console.log(data.firstName);

    // TODO: Add social
    var vcard =
      "BEGIN:VCARD\nVERSION:3.0\nFN:" +
      // "\nTEL;TYPE=work,voice:" +
      "\nTEL;Work;TYPE=voice,work:" +
      data.phoneNumber +
      "\nEMAIL;Work email;TYPE=work:" +
      data.email +
      "\nTITLE:" +
      data.jobTitle +
      "\nPHOTO;TYPE=JPEG;ENCODING=b:" +
      data.thumbnailBase64 +
      "\nORG:" +
      data.companyName +
      "\nN;CHARSET=utf-8:" +
      data.firstName +
      ";" +
      data.lastName +
      ";;;" +
      "\nURL;Website;TYPE=Website:" +
      // "\nitem1.URL;type=pref:" +
      data.websiteLink +
      // "\nitem1.X-ABLabel:Website" +
      // "\nitem1.X-ABLabel:_$!<HomePage>!$_" +
      // "\nURL;FREE NUDES;TYPE=FREE NUDES:" +
      // "\nitem1.URL;type=pref:" +
      // "http://www.patience-is-a-virtue.org/" +
      // "\nGEO:39.95;-75.1667" +
      "\nEND:VCARD";
    var blob = new Blob([vcard], { type: "text/vcard" });
    var url = URL.createObjectURL(blob);

    const newLink = document.createElement("a");
    newLink.download = data.firstName + ".vcf";
    newLink.textContent = data.firstName;
    newLink.href = url;

    newLink.click();
    setIsLoading(false);
  }

  return (
    <>
      <div
        className="App"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          height: "100vh",
        }}
      >
        <img
          src={data?.displayImage}
          width="100vw"
          height="25vh"
          className={styles.displayImage}
        />
        <div className={styles.card}>
          <MyDrawer username={data.username} firstName={data.firstName} />
          {/* TODO: Maybe change the background color to eef2f5 */}
          <div
            style={{
              display: "flex",
              width: "95vw",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                marginTop: "15px",
                fontSize: "30px",
                fontFamily: "Roboto",
                fontWeight: "500",
              }}
            >
              {data.firstName ?? ""}
            </div>
            <div
              style={{
                fontSize: "30px",
                fontFamily: "Roboto",
                fontWeight: "500",
              }}
            >
              {data.lastName ?? ""}
            </div>
            <div
              style={{
                fontSize: "20px",
                fontFamily: "Roboto",
                fontWeight: "300",
              }}
            >
              {data.jobTitle && `${data.jobTitle}, `}
              {data.companyName && `@${data.companyName}`}
            </div>
            <div
              style={{
                fontSize: "16px",
                fontFamily: "Roboto",
                fontWeight: "300",
              }}
            >
              {data.city && `${data.city}, `}
              Ελλαδα
            </div>
          </div>

          <div
            style={{
              marginTop: "15px",
              width: "95vw",
            }}
          >
            {/* TODO: Make one component for all the information items 
          in the card, and then try to fix the last index. (borderline)
           */}

            <CardInfoRow icon={<HiOutlineAtSymbol />} userData={data.email} />
            <CardInfoRow icon={<FaPhoneAlt />} userData={data.phoneNumber} />
            <CardInfoRow
              icon={<FaLocationDot />}
              userData={data.address}
              extraData={data?.addressDetails}
            />
            <CardInfoRow icon={<TfiWorld />} userData={data.websiteLink} />
          </div>

          {/* TODO: Replace with button so it can be disabled when loading */}
          <div
            style={{
              marginTop: "30px",
              backgroundColor: "black",
              width: "95vw",
              height: "45px",
              borderRadius: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={generateVCard}
          >
            {isLoading ? (
              <PropagateLoader color="white" loading={true} size={5} />
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <MdOutlineSaveAlt
                  size={18}
                  color="white"
                  style={{ position: "relative", bottom: "2px" }}
                />
                <p
                  style={{
                    color: "white",
                    fontFamily: "Roboto",
                    fontWeight: "500",
                    fontSize: "16px",
                    marginLeft: "8px",
                  }}
                >
                  Αποθήκευση Επαφής
                </p>
              </div>
            )}
          </div>

          <div
            style={{
              backgroundColor: "#E7E7E7",
              marginTop: "30px",
              width: "95vw",
              height: "100px",
              borderRadius: "20px",
              display: "flex",
              justifyContent: "space-evenly",
              marginBottom: "15px",
              alignItems: "center",
            }}
          >
            {data.facebookLink && (
              <a href={data.facebookLink} target="_blank">
                <img
                  src={data.facebookIcon}
                  width={80}
                  height={80}
                  style={{}}
                />
              </a>
            )}

            {data.instagramLink && (
              <a href={data.instagramLink} target="_blank">
                <img
                  src={data.instagramIcon}
                  width={80}
                  height={80}
                  style={{}}
                />
              </a>
            )}

            <a
              href={`viber://contact?number=%${data.phoneNumber}`}
              target="_blank"
            >
              <img src={data.viberIcon} width={80} height={80} style={{}} />
            </a>

            <a href={`https://wa.me/${data.phoneNumber}`}>
              <img src={data.whatsappIcon} width={80} height={80} style={{}} />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
