import React from "react";

function CardInfoRow({ icon, userData, extraData }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        paddingBlock: "10px",
        borderBottom: "1px #A3A3A3 dashed",
      }}
    >
      {icon}

      <span
        style={{
          marginLeft: "6px",
          fontFamily: "Roboto",
          fontWeight: "500",
          textAlign: "left",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {userData}
        <span
          style={{
            fontFamily: "Roboto",
            fontWeight: "400",
            color: "#555555",
            fontSize: "15px",
          }}
        >
          {extraData}
        </span>
      </span>
    </div>
  );
}

export default CardInfoRow;
