import { getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getFirestore,
  collection,
  getDocs,
  Timestamp,
} from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAij4OfLtYbqkU1dv0d80bx0gT4ijB2174",
  authDomain: "prodaction-2024.firebaseapp.com",
  projectId: "prodaction-2024",
  storageBucket: "prodaction-2024.appspot.com",
  messagingSenderId: "383114116611",
  appId: "1:383114116611:web:762015055e835094339836",
  measurementId: "G-0NRR3VJ7M5",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const db = getFirestore();
const storage = getStorage(app);
const auth = getAuth(app);
const timestamp = Timestamp.now();

export { db, timestamp, storage, auth, collection, getDocs };
