import { Drawer } from "vaul";
import styles from "./MyDrawer.module.css";
import { useForm } from "react-hook-form";
import { Timestamp, addDoc, collection, doc } from "firebase/firestore";
import { db } from "../firebase/firebase";
import { useState, useRef } from "react";
import PropagateLoader from "react-spinners/PropagateLoader";

export function MyDrawer({ username, firstName }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm();
  const intervalRef = useRef();

  async function onSubmit(data) {
    const { nameRequired, emailRequired } = data;

    setIsLoading(true);
    await addDoc(collection(db, `users/${username}/contacts`), {
      createdAt: Timestamp.now(),
      name: nameRequired,
      email: emailRequired,
    })
      .then((e) => {
        setIsOpen(false);
        console.log("done");
      })
      .catch((error) => {
        console.log(error);
      });

    setIsLoading(false);
  }

  function onCloseHandler() {
    setIsOpen(false);
    reset();
  }

  function EmailErrorHandler() {
    if (errors.emailRequired.type === "pattern") {
      return (
        <span
          style={{
            color: "black",
            fontFamily: "Roboto",
            marginTop: "1px",
          }}
        >
          Συμπληρώστε σωστό email
        </span>
      );
    }
    return (
      <span
        style={{
          color: "black",
          fontFamily: "Roboto",
          marginTop: "1px",
        }}
      >
        Υποχρεωτικό πεδίο
      </span>
    );
  }

  return (
    <Drawer.Root onClose={onCloseHandler} open={isOpen} dismissible={true}>
      <Drawer.Trigger asChild>
        <div
          onClick={() => setIsOpen(true)}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
            border: "black solid 1.7px",
            width: "95vw",
            height: "40px",
            marginTop: "15px",
            borderRadius: "7px",
          }}
        >
          <p style={{ fontFamily: "Roboto", fontWeight: "500" }}>
            Ανταλλαγή Επαφής
          </p>
        </div>
      </Drawer.Trigger>
      <Drawer.Portal>
        <Drawer.Overlay className={styles.overlay} />
        <Drawer.Content className={styles.content} ref={intervalRef}>
          <div className={styles.rootContainer}>
            <div className={styles.drag} />
            <div className={styles.secondContainer}>
              <Drawer.Title
                style={{
                  textAlign: "center",
                  backgroundColor: "white",
                  //   margin: 0,
                }}
              >
                Ανταλλαγή στοιχείων με τον/την {firstName}
              </Drawer.Title>

              <form
                onSubmit={handleSubmit(onSubmit)}
                style={{ display: "flex", flexDirection: "column" }}
              >
                {/* register your input into the hook by invoking the "register" function */}
                <input
                  name="name"
                  placeholder="Όνομα"
                  {...register("nameRequired", { required: true })}
                  style={{
                    border: errors.nameRequired
                      ? "2.5px #ff4545 solid"
                      : "1.9px #6B6B6B solid",
                    padding: "15px",
                    fontSize: "18px",
                    borderRadius: "5px",
                  }}
                />

                {errors.nameRequired && (
                  <span
                    style={{
                      color: "black",
                      fontFamily: "Roboto",
                      marginTop: "1px",
                    }}
                  >
                    Υποχρεωτικό πεδίο
                  </span>
                )}

                {/* TODO: include validation with required or other standard HTML validation rules */}
                <input
                  name="email"
                  type="email"
                  placeholder="Email"
                  autoCapitalize="none"
                  {...register("emailRequired", {
                    required: true,
                    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  })}
                  style={{
                    border: errors.emailRequired
                      ? "2.5px #ff4545 solid"
                      : "1.9px #6B6B6B solid",
                    padding: "15px",
                    marginTop: errors.nameRequired ? "10px" : "30px",
                    fontSize: "18px",
                    borderRadius: "5px",
                  }}
                />

                {/* errors will return when field validation fails  */}
                {errors.emailRequired && <EmailErrorHandler />}

                <input
                  name="phoneNumber"
                  type="number"
                  placeholder="Τηλέφωνο"
                  autoCapitalize="none"
                  // {...register("emailRequired", {
                  //   required: true,
                  //   pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  // })}
                  style={{
                    // border: errors.emailRequired
                    //   ? "2.5px #ff4545 solid"
                    border: "1.9px #6B6B6B solid",
                    padding: "15px",
                    marginTop: errors.nameRequired ? "10px" : "30px",
                    fontSize: "18px",
                    borderRadius: "5px",
                  }}
                />

                <button
                  type="submit"
                  style={{
                    borderRadius: "10px",
                    height: "60px",
                    marginTop: errors.emailRequired ? "10px" : "30px",
                    backgroundColor: "black",
                    color: "white",
                    fontSize: "18px",
                    fontFamily: "Roboto",
                    fontWeight: "600",
                  }}
                >
                  {isLoading ? (
                    // TODO: maybe change the color to 016dff
                    <PropagateLoader color="white" loading={true} size={6} />
                  ) : (
                    "Αποστολή"
                  )}
                </button>
              </form>
            </div>
          </div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
}
